<template>
  <div>
    <v-card class="not-print" color="grey lighten-4" outlined>
      <v-card-text>
        <v-row>
          <v-col>
            <app-number-field
              :precision="0"
              v-model="pageSettings.itensPerPage"
              outlined
              label="Itens por pagina"
            />
          </v-col>
          <v-col>
            <app-number-field
              v-model="pageSettings.margin"
              outlined
              label="Margem da pagina(cm)"
            />
          </v-col>
          <v-col>
            <app-number-field
              v-model="pageSettings.padding"
              outlined
              label="Preenchimento da pagina(cm)"
            />
          </v-col>
          <v-col>
            <app-number-field
              v-model="tagSettings.height"
              outlined
              label="Altura da etiqueta(cm)"
            />
          </v-col>
          <v-col>
            <app-number-field
              v-model="tagSettings.width"
              outlined
              label="Largura da etiqueta(cm)"
            />
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col cols="4" class="d-flex justify-space-between">
            <v-btn-toggle dense>
              <v-btn @click="setAlignment('start')">
                <v-icon>mdi-format-align-left</v-icon>
              </v-btn>
              <v-btn @click="setAlignment('center')">
                <v-icon>mdi-format-align-center</v-icon>
              </v-btn>
              <v-btn @click="setAlignment('end')">
                <v-icon>mdi-format-align-right</v-icon>
              </v-btn>
              <v-btn @click="setAlignment('space-between')">
                <v-icon>mdi-format-align-justify</v-icon>
              </v-btn>
            </v-btn-toggle>

            <v-btn-toggle dense>
              <v-btn @click="setScreen('portrait')">
                <v-icon>mdi-crop-portrait</v-icon>
              </v-btn>
              <v-btn @click="setScreen('landscape')">
                <v-icon>mdi-crop-landscape</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>
    <div
      id="printMe"
      :style="`padding: ${pageSettings.padding}cm; margin:${pageSettings.margin}cm;`"
      :class="`justify-${pageSettings.alignment}`"
      class="d-flex row page"
      size="A4"
      v-for="page in pages"
      :key="`page${page}`"
    >
      <template v-for="product in pageSettings.itensPerPage">
        <ProductTag
          :key="`product${product}/${page}`"
          :product="getProduct(selectedProducts[getIndex(page, product)])"
          v-if="getProduct(selectedProducts[getIndex(page, product)])"
          :tagSettings="tagSettings"
        />
      </template>
    </div>
  </div>
</template>
<script>
import ProductTag from "@/components/tags/sections/ProductTag";

export default {
  components: {
    ProductTag,
  },

  data() {
    return {
      pageSettings: {
        itensPerPage: 20,
        height: 29.7,
        width: 21,
        padding: 0,
        margin: 0,
        alignment: "center",
      },
      tagSettings: {
        height: 3,
        width: 6,
      },
    };
  },

  computed: {
    selectedProducts() {
      return JSON.parse(this.$store.getters["productsPrintTag/getProducts"]);
    },

    productsCount() {
      if (this.selectedProducts) {
        return this.selectedProducts.length;
      }
      return 1;
    },

    pages() {
      return Math.ceil(this.productsCount / this.pageSettings.itensPerPage);
    },
  },

  methods: {
    getProduct(product) {
      return product != null || product != undefined ? product : "";
    },

    getIndex(page_settings, item) {
      return (page_settings - 1) * this.pageSettings.itensPerPage + item - 1;
    },

    hasItemOnIndex(page_settings, item) {
      return this.getIndex(page_settings, item) < this.selectedProducts.length;
    },
  },
};
</script>


<style  scoped>
* {
  text-transform: uppercase;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  color: rgb(0, 0, 0);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 11pt;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

@page {
  margin: 0.5cm;
  padding: 0.5cm;
}

@media print {
  .page {
    display: block;
    margin: initial;
    border: rgba(0, 0, 0, 0.1);
    border-radius: initial;
    width: initial;
    height: 13cm;
    box-shadow: initial;
    background: initial;
    page-break-after: avoid;
    page-break-before: always;
    page-break-inside: avoid;
  }

  .not-print {
    display: none;
  }
}

hr.color {
  border: 1px solid black;
}
</style>

 