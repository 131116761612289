<template>
  <v-card
    :height="$printTag.size(tagSettings.height)"
    :width="$printTag.size(tagSettings.width)"
    class="container--fluid card"
    outlined
  >
    <div style="word-break: break-word" class="pa-2">
      <b>{{ product.code }} - {{ product.name }}</b>
      <div class="d-flex flex-column">
        <span>
          À Vista R$
          {{ $format.decimal(product.product_variants[0].price_cash) }}
        </span>
        <small>
          À Prazo R$
          {{ $format.decimal(product.product_variants[0].price_forward) }}
        </small>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    tagSettings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ratio() {
      return this.tagSettings.width / this.tagSettings.height;
    },
  },
};
</script>

